<template>
  <div class="button-filled" :class="[isFullWidth ? 'full-width' : null]">
    <div class="button" @click="onClick()">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isFullWidth: Boolean,
    text: String,
    onClick: Function
  }
};
</script>

<style lang="scss" scoped>
.button-filled {
  display: inline-block;
  margin: 25px 0 0 0;

  &.full-width {
    display: block;
    width: 100%;

    .button {
      width: 100%;
    }
  }

  .button {
    display: inline-block;
    background-color: $main-color;
    color: $white;
    border: 2px solid $main-color;
    border-radius: 3px;
    text-align: center;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: $white;
      color: $main-color;
    }
  }
}
</style>
