<template>
  <div class="platform-languages">
    <ActionPanel :isReady="isReady"> </ActionPanel>

    <Container
      class="wrapper-languages"
      :fullWidth="true"
      :flexWidth="'all'"
      :flexJustify="'space-around'"
    >
      <InnerPanel
        class="languages-column languages"
        :isReady="isReady"
        :hasShadow="true"
        :title="translations.labels.languages"
      >
        <div class="lang-list">
          <div
            v-for="lang in languages"
            :key="lang.code"
            class="language"
            @click="selectLang(lang)"
          >
            <div class="wrapper-inner">
              <Svg-icon class="flag" :isFlag="true" :icon="lang.code" />
              <h5 class="title">{{ lang.description }}</h5>
            </div>
          </div>
        </div>
      </InnerPanel>

      <InnerPanel
        class="languages-column selected-languages"
        :isReady="isReady"
        :hasShadow="true"
        :title="translations.labels.languages_selected"
      >
        <div class="lang-list">
          <div
            v-for="lang in selectedLanguages"
            :key="lang.code"
            class="language"
            @click="deselectLang(lang)"
          >
            <div class="wrapper-inner">
              <Svg-icon class="flag" :isFlag="true" :icon="lang.code" />
              <h5 class="title">{{ lang.description }}</h5>
            </div>
          </div>
        </div>
      </InnerPanel>
      <InnerPanel
        class="languages-column selected-languages"
        :isReady="isReady"
        :hasShadow="true"
        title="Default language"
      >
        <div class="lang-list">
          <div
            v-for="lang in selectedLanguages"
            :key="lang.code"
            class="language"
            @click="setAsDefault(lang)"
          >
            <div class="wrapper-inner">
              <Svg-icon class="flag" :isFlag="true" :icon="lang.code" />
              <h5 v-if="lang.isDefault" class="default-label">
                {{ lang.description }}
              </h5>
              <h5 v-else class="title">{{ lang.description }}</h5>
            </div>
          </div>
        </div>
      </InnerPanel>
    </Container>
  </div>
</template>

<script>
import Component from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import ButtonFilled from "@/components/dom/ButtonFilled";
import Container from "@/components/dom/Container";
import InnerPanel from "@/components/panel/InnerPanel";
import Loader from "@/components/dom/Loader";
import TitleBase from "@/components/dom/TitleBase";
import SvgIcon from "@/components/dom/SvgIcon";
import languageList from "@/assets/languages.json";

@Component({
  components: {
    ActionPanel,
    ButtonFilled,
    Container,
    InnerPanel,
    Loader,
    TitleBase,
    SvgIcon,
  },
  inject: {
    languageService: "languageService",
    toastService: "toastService",
  },
})
export default class PlatformLanguages {
  isReady = true;
  languages = [];
  selectedLanguages = [];
  activeLanguageList = [];

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  moveItemToList(item, sourceList, destinationList) {
    const language = sourceList.filter((l) => {
      return l.code == item.code;
    })[0];
    const languageIndex = sourceList.indexOf(language);
    destinationList.push(language);
    sourceList.splice(languageIndex, 1);
  }

  selectLang(lang) {
    this.moveItemToList(lang, this.languages, this.selectedLanguages);
    this.saveLanguage(lang);
  }

  deselectLang(lang) {
    this.moveItemToList(lang, this.selectedLanguages, this.languages);
    this.deleteLanguage(lang);
  }

  get isThereAnyDefault () {
    const defaultLanguage = this.activeLanguageList.filter(l => l.isDefault == true );
    return defaultLanguage.length > 0;
  }

  async setAsDefault(lang) {
    this.isReady = false;
    try {
      if (!lang.isDefault) {
        await this.languageService.setAsDefault(lang.id);
      }

      if (lang.isDefault) {
        if(!this.isThereAnyDefault){
        await this.languageService.setNotAsDefault(lang.id);
        }
        else{
        this.toastService.error("you need at least one default language");
        }
      }
    } catch (error) {
      this.isReady = true;
      this.toastService.error(JSON.parse(JSON.stringify(error)).description);
    }

    this.getLanguages();
    this.isReady = true;
  }

  async saveLanguage(language) {
    this.isReady = false;

    try {
      await this.languageService.save(language);
      this.isReady = true;
      this.$store.commit("SET_LANGUAGES", this.selectedLanguages);
    } catch (error) {
      this.isReady = true;
      this.toastService.error(JSON.parse(JSON.stringify(error)).description);
    }

    this.getLanguages();
  }

  async deleteLanguage(language) {
    this.isReady = false;
    if (!language.isDefault) {
      try {
        await this.languageService.remove(language.id);
        this.isReady = true;
        this.$store.commit("SET_LANGUAGES", this.selectedLanguages);
      } catch (error) {
        this.isReady = true;
        this.toastService.error(JSON.parse(JSON.stringify(error)).description);
      }
    } else {
      this.isReady = true;
      alert("Can not delete platform default language");
    }

    this.getLanguages();
  }

  sortLanguageList(langList) {
    langList.sort((a, b) => {
      if (a.description < b.description) return -1;
      if (a.description > b.description) return 1;
      return 0;
    });
  }

  async getLanguages() {
    this.isReady = false;
    this.activeLanguageList = await this.languageService.list();
    this.languages = languageList.filter((l) => {
      let results = this.activeLanguageList.filter((al) => {
        return al.code == l.code;
      });
      return results.length == 0;
    });

    this.selectedLanguages = JSON.parse(
      JSON.stringify(this.activeLanguageList)
    );

    this.sortLanguageList(this.selectedLanguages);

    this.isReady = true;
  }

  created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.platformLanguages
    );
  }

  mounted() {
    this.getLanguages();
  }
}
</script>
<style lang="scss">
.platform-languages {
  .wrapper-languages {
    .languages-column {
      width: 400px;

      .lang-list {
        max-height: 350px;

        .language {
          border-radius: 25px;
          box-shadow: 0px 0px 2px 0px $grey-light;
          margin: 10px 0 0 0;
          padding: 8px 20px;
          transition: 200ms;
          cursor: pointer;

          &.disabled {
            background-color: $grey-lighter;
          }

          &:hover {
            transform: translateX(10px);
            box-shadow: 0px 0px 10px 0px $blue-light;
          }

          .wrapper-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .default-label {
              margin-left: 20px;
              font-weight: 900;
              color: #009900;
              font-size: 15px;
            }

            .flag {
              width: 32px;

              svg {
                display: flex;
              }
            }

            .title {
              margin-left: 20px;
            }
          }

          .is-default {
            background-color: $blue-light;
          }
        }
      }
    }
  }
}
</style>
